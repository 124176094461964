<template>
  <div>
    <v-card-title>
      <span class="headline">{{ cardTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="formPrimeCare" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field label="CNPJ *" readonly :value="email.cnpj" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="email.companyName"
                label="Nome da empresa*"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="email.currentOperator"
                :items="insurers"
                label="Operadora atual *"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="email.currentPlan"
                label="Plano Atual*"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="datePicker"
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Início de Vigência *"
                    prepend-icon="mdi-calendar"
                    readonly
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="email.vigencyDate"
                  no-title
                  @input="setDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="close()">
        Fechar
      </v-btn>
      <v-btn color="green darken-1" text @click="sendEmail()">
        Enviar
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import rules from "@/utils/rules";
import { emailTypes, insurers } from "@/utils/dps.utils";
import { isEmpty, uniq } from "lodash";
import { post } from "@/utils/api";

export default {
  name: "PrimeCare",
  props: ["toggle", "items", "type"],
  mounted() {
    if (!isEmpty(this.items)) {
      const beneficiaries = this.getBeneficiaries();
      this.email.type = this.type;
      this.email.cnpj = this.items[0].cnpj;
      this.email.currentOperator = this.getInsurer(this.items[0].insurer);
      this.email.beneficiaries = beneficiaries.map(beneficiary => ({
        name: `${beneficiary.name} ${beneficiary.lastName}`,
        telephone: beneficiary.phone,
        email: beneficiary.email,
        cpf: beneficiary.cpf,
        cpt: beneficiary.cpt
      }));
    }
  },
  data() {
    return {
      rules: rules,
      email: {
        type: "",
        cnpj: "",
        companyName: "",
        currentOperator: "",
        vigencyDate: "",
        beneficiaries: []
      },
      datePicker: false,
      date: "",
      cpt: {
        errorMessage: "Não há CPTs preenchidas"
      },
      primecare: {
        errorMessage: "Não há membros imediatos"
      }
    };
  },
  computed: {
    cardTitle() {
      return emailTypes.find(x => x.value == this.type)?.text;
    },
    insurers() {
      return insurers.map(x => x.text);
    }
  },
  methods: {
    getBeneficiaries() {
      if (this.type === "cpt") {
        return this.items.filter(item => !isEmpty(item.cpt));
      }
      return this.items.filter(item => item.immediateMember);
    },
    close() {
      if (this.toggle) this.toggle();
    },
    setDate(date) {
      this.email.vigencyDate = date;
      this.date = this.parseDate(date);

      this.datePicker = false;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.toString().split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    getInsurer(insurer) {
      if (insurer) {
        return insurers.find(i => i.value === insurer).text;
      }
      return "Unimed";
    },
    async sendEmail() {
      if (this.$refs.formPrimeCare.validate()) {
        if (isEmpty(this.email.beneficiaries)) {
          return this.$store.commit("showAlert", {
            type: "error",
            message: this.$data[this.type].errorMessage
          });
        }

        this.$store.commit("loading", true);

        const { email } = this.$store.state.user;

        try {
          const body = {
            ...this.email,
            cc: uniq([email])
          };
          await post({ path: "/email-primecare", body });
          this.$store.commit("showAlert", {
            type: "success",
            message: "Email enviado com sucesso"
          });
          this.close();
        } catch (error) {
          this.$store.commit("showAlert", {
            type: "error",
            message: "Não foi possível enviar o email"
          });
        } finally {
          this.$store.commit("loading", false);
        }
      }
    }
  }
};
</script>
